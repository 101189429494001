<template>
  <div
    class="test-method-matrix px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-1/6"></div>
      <div class="w-2/3">
        <TitleComponent class="py-8" title="Test Method Matrix" size="large" />

        <p class="font-bold">
          The Test Method Matrix is a database outlining the purpose, key test
          parameters, and indicators (including pictures, videos, procedures,
          and hazards assessment) for each sensitivity and reactivity
          characterization test for both In-process and UN test methodologies.
        </p>

        <div class="flex flex-wrap text-center mt-8">
          <div class="w-full md:w-1/2">
            <router-link class="text-3xl link" to="/in-process-classification">
              <div
                style="background-color:#ef9a3d;width:3.5rem;height:3.5rem;"
                class="rounded-full flex items-center justify-center m-auto"
              >
                <img
                  class="icon-white"
                  width="30"
                  height="30"
                  src="@/assets/stream-solid.svg"
                />
              </div>
              In-process Testing Resources
            </router-link>
          </div>
          <div class="w-full md:w-1/2">
            <router-link
              class="text-3xl link"
              to="/un-manual-classification-system"
            >
              <div
                style="background-color:#ef9a3d;width:3.5rem;height:3.5rem;"
                class="rounded-full flex items-center justify-center m-auto"
              >
                <img
                  class="icon-white"
                  width="30"
                  height="30"
                  src="@/assets/stream-solid.svg"
                />
              </div>
              UN Testing Resources
            </router-link>
          </div>
          <div class="w-full text-left mt-8 text-lg">
            <p class="leading-relaxed" style="color:#7e8890;">
              Terms of Use
              <br />
              All material on this website is copyrighted by the ET Users
              Group™. By accessing the content in the Test Methods Matrix™ you
              agree to the following:
            </p>
          </div>

          <div
            style="color:#7e8890;"
            class="w-full text-left ml-10 mt-4 text-lg"
          >
            <ul class="list-disc">
              <li class="mt-2">
                You will not make copies of this material or otherwise make this
                content available offline for others (such as but not limited to
                creating training materials) without written consent from the ET
                Users Group;
              </li>
              <li class="mt-2">
                You will direct inquiries regarding access to this content to
                the ET Users Group;
              </li>
              <li class="mt-2">
                You will not hold liable the ET Users Group or any associated
                entity for damages caused by the use or misuse of this content.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="w-1/6"></div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";

export default {
  components: {
    TitleComponent
  }
};
</script>

<style lang="scss">
.test-method-matrix {
  .icon-white {
    filter: brightness(0) invert(1);
  }

  .link:hover {
    color: #ef9a3d;
  }
}
</style>
